(function($){

    $.fn.Parallax = function(options) {

        // cache selector
        var element = $(this);

        // check if elementect exists
        if(element.length <= 0) return;

        // merge defaults with custom vars
        options = $.extend({}, $.fn.Parallax.defaults, options);
        options.startPosition = element.offset().top + options.delay;

        // bind scroll event to update position
        $(window).bind('scroll', updatePosition);

        // listen for screen resize
        $(window).resize(function() {

            if($(window).width() <= 400) {
                options.disable = false;
                element.removeAttr('style');
            } else {
                options.disable = false;
                updatePosition();
            }

        });

        // update element position
        function updatePosition() {

            if(options.disable) return false;

            var scrollTop = $(window).scrollTop();

            if(scrollTop > options.startPosition) {

                element.css('-webkit-transform', 'translate3d(0,' + getPosition() + 'px,0)')
                    .css('transform', 'translate3d(0,' + getPosition() + 'px,0)');

            } else {

                element.css('-webkit-transform', 'translate3d(0,' + getPosition() + 'px,0)')
                    .css('transform', 'translate3d(0,' + getPosition() + 'px,0)');

            }

        }

        // get new position
        function getPosition() {

            // Calculate new position based on speed / scroll position / start position
            return ($(window).scrollTop() * options.speed) - (options.startPosition * options.speed) + options.start;

        };

        return element.each(function(){

            updatePosition();

        });

    }

    $.fn.Parallax.defaults = {
        disable: false,
        property:'top',
        speed:0.2,
        start:0,
        delay:0
    };


})(jQuery);