/**
 * Main Javascript File
 *
 * v1.0.0
 */
$ = jQuery.noConflict();

var mainScript = function () {
    'use strict';


    /**
     * init page
     */
    var init = function () {

        var map = null,
            delay = 0;

        if (window.navigator.userAgent.indexOf('Edge/') > 0) {
            // Add hack for Edge browser
            delay = 250;
        }

        // Show Loader
        /*
         NProgress.configure({
         minimum: 0.2,
         showSpinner: false,
         parent: '#progress',
         easing: 'ease',
         speed: 650,
         trickleRate: 0.1,
         trickleSpeed: 650
         });
         */

        setTimeout(function () {
            // remove on hold class from HTML
            dh.removeClass('html', 'js-on-hold');

            setTimeout(function () {
                // remove class of deferred loading
                load_deferred_images('.load-deferred');
            }, delay);

        }, delay);

        window.isMobile = false;
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            window.isMobile = true;
        }

        //Detect Browser
        window.isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        window.isSafari = /^((?!chrome).)*safari/i.test(navigator.userAgent);
        window.isChrome = /chrom(e|ium)/.test(navigator.userAgent.toLowerCase());
        window.isChromeiOS = navigator.userAgent.match('CriOS');
        window.isMSIE = navigator.userAgent.match('MSIE');
        window.isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1;
        window.isiPad = navigator.userAgent.match(/iPad/i) !== null;

        //Prepare for CSS Fixes
        if (window.isSafari) {
            $('html').addClass('safari');
        }
        if (window.isFirefox) {
            $('html').addClass('firefox');
        }
        if (window.isChrome) {
            $('html').addClass('chrome');
        }
        if (window.isMSIE) {
            $('html').addClass('msie');
        }
        if (window.isAndroid) {
            $('html').addClass('android');
        }

        //Detect Mobile
        if (window.isMobile) {
            $('html').addClass('mobile');
        }

        // enable SVG use tag for legacy browsers
        svg4everybody();

        cookie_law();

        //init_cookie_nl();

        init_fastclick();

        init_slider();

        scroll_to_position();

        init_navigation();

        auto_hide_navigation();

        parallaxScroll();

        //init_hashchange();

        cf7callback();

        // Google Maps
        init_maps();

        init_photoswiper();

        console.log('main.js: page initialized');


    };

    /**
     * Preload queue
     */
    var preload_queue = function (imgs, index) {
        console.log('preload_queue:', imgs.length, index);
        index = index || 0;

        if (imgs && imgs.length > index) {
            var img = new Image();
            img.onload = function () {
                setTimeout(function () {
                    // reload next after 1 sec
                    preload_queue(imgs, index + 1);
                }, 1000);
            };
            img.src = imgs[index];
        }
    };

    /**
     * Load deferred images
     */
    var load_deferred_images = function (selector) {
        console.log('load deferred images (' + selector + ')');

        var el = dh.select(selector),
            preloader = $('#progress'),
            elCount = el.length - 1,
            //increment =  Math.floor(100 / elCount);
            increment = 10 / elCount;

        console.log('count:', elCount + ', Increment:' + increment);
        //console.log('el:', el);

        for (var i = 0; i < el.length; i++) {
            if (el[i].getAttribute('data-src')) {

                if(el[i].tagName.toLowerCase() == 'img'){
                    el[i].setAttribute('src', el[i].getAttribute('data-src'));
                }else {
                    el[i].style.backgroundImage = "url('" + el[i].getAttribute('data-src') + "')";
                }
                //el[i].setAttribute('src', el[i].getAttribute('data-src'));
                //el[i].style.backgroundImage = "url('" + el[i].getAttribute('data-src') + "')";
            }
        }

        //preload_images();

    };


    /**
     * CF7 Submit Callback
     */
    var cf7callback = function () {

        document.addEventListener( 'wpcf7mailsent', function( event ) {

            //console.log(event);

            var formId = event.detail['contactFormId'],
                tagId = $('#'+event.detail['id']),
                //parentContainer = tagId.closest('.card'),
                //formOverlay = parentContainer.find('.wpcf7-okay'),
                track = tagId.find('.wpcf7-track'),
                redirect = tagId.find('.wpc7-redirect'),
                redirectURL = redirect.val();

            if(redirect.length > 0) {
                location = redirectURL;
            }

            if(track.length > 0){
                ga('send', 'event', 'Form ' + formId, 'submit');
            }

        }, false );

    };


    /**
     * Cookie Consent
     */
    var cookie_law = function () {

        window.addEventListener("load", function () {
            window.cookieconsent.initialise({
                theme: "custom",
                position: "bottom-right",
                content: {
                    message: "Wir verwenden Cookies, um unsere Webseite für Sie möglichst benutzerfreundlich zu gestalten. Wenn Sie fortfahren, nehmen wir an, dass Sie mit der Verwendung von Cookies auf dieser Webseite einverstanden sind.",
                    dismiss: "OK",
                    link: "Mehr erfahren"
                },
                revokable: true,
                onStatusChange: function (status) {
                    console.log(this.hasConsented() ?
                        'enable cookies' : 'disable cookies');
                },
                elements: {
                    dismiss: '<a aria-label="dismiss cookie message" tabindex="0" class="btn btn-sm btn-primary cc-btn cc-dismiss">{{dismiss}}</a>',
                }
            });

        });

    };


    /**
     * Cookie Newsletter
     *
     var init_cookie_nl = function () {

        var cookieModalId = 'DesignDistrictNewsletter',
            cookieModal = $('#kmModal');

        var my_cookie = $.cookie(cookieModalId);
        if (my_cookie && my_cookie == "dismiss") {


        } else {

            setTimeout(function () {

                cookieModal.modal('show');

            }, 5000);

        }

        cookieModal.find('.close').click(function () {
            $.cookie(cookieModalId, 'dismiss', {
                path: '/',
                expires: 14
            });
        });


    }; */


    /**
     * Fast Click
     */
    var init_fastclick = function () {

        $(function () {
            FastClick.attach(document.body);
        });

    }

    /**
     * Init Sidebars
     */
    var init_navigation = function () {

        $('.hamburger, .nav-backdrop').click(function () {

            $('.hamburger').toggleClass('is-active not-active');
            $('body').toggleClass('nav-hidden nav-show');

            return false;
        });

    };


    /**
     * Auto Hide Navigation
     */
    var auto_hide_navigation = function () {

        var el = dh.select('.float-box');
        if (el.length === 0) {
            // No slider on this page --> skip
            return null;
        }

        var didScroll = false,
            changeHeaderOn = 300;

        window.addEventListener('scroll', function (event) {
            if (!didScroll) {
                didScroll = true;
                setTimeout(scrollPage, 250);
            }
        }, false);


        function scrollPage() {
            var sy = scrollY();
            if (sy >= changeHeaderOn) {
                $('.float-box').addClass('show');
            } else {
                $('.float-box').removeClass('show');
            }
            didScroll = false;
        }

        function scrollY() {
            return window.pageYOffset || $('body, html').scrollTop;
        }

    };


    /**
     * Slider
     */
    var init_slider = function () {

        var el = dh.select('.slider-main');
        if (el.length === 0) {
            // No slider on this page --> skip
            return null;
        }

        //var parent = [];
        var sliders = [];
        var thumbSliders = [];

        /*
         $('.slider-parent').each(function (index, element) {
         $(this).addClass('psw' + index);

         console.log(thumbs);

         var pslider = new Swiper('.psw' + index, {
         speed: 350,
         spaceBetween: 20,
         effect: 'slide',
         simulateTouch: false,
         autoplay: false,
         grabCursor: false,
         keyboardControl: false,
         loop: false,
         });
         parent.push(pslider);

         });
         */

        var pslider = new Swiper('.slider-parent', {
            speed: 0,
            spaceBetween: 20,
            effect: 'fade',
            simulateTouch: false,
            autoplay: false,
            grabCursor: false,
            keyboardControl: false,
            loop: false,
        });

        $('.swiper-tabs a').on('click', function () {

            var index = $(this).closest('li').index();

            $(this).closest('ul').find('li').removeClass('active');
            $(this).closest('li').addClass('active');

            pslider.slideTo(index, 350);

            return false;
        });

        $('.slider-main').each(function (index, element) {
            $(this).addClass('sw' + index);
            $(this).find('.button-prev').addClass('sw-prev' + index);
            $(this).find('.button-next').addClass('sw-next' + index);
            var thumbs = $(this).parent().find('.slider-thumbs');
            thumbs.addClass('tsw'+ index);

            console.log(thumbs);

            var slider = new Swiper('.sw' + index, {
                speed: 350,
                spaceBetween: 0,
                effect: 'slide',
                autoplay: false,
                grabCursor: false,
                keyboardControl: false,
                loop: false,
                navigation: {
                    prevEl: '.sw-prev' + index,
                    nextEl: '.sw-next' + index,
                },
                preloadImages: false,
                lazy: {
                    loadPrevNext: true,
                }
                //thumbs: {
                //swiper: galleryThumbs
                //}
                //lazyLoadingInPrevNext: true,
                //lazyLoadingInPrevNextAmount: 1
            });
            sliders.push(slider);

            if(thumbs.length !== 0) {

                var galleryThumbs = new Swiper('.tsw' + index, {
                    speed: 350,
                    spaceBetween: 0,
                    centeredSlides: true,
                    slidesPerView: 'auto',
                    slideToClickedSlide: true,
                    grabCursor: false,
                    keyboardControl: false,
                    loop: false,
                    preloadImages: false,
                    lazy: {
                        loadPrevNext: true,
                        loadPrevNextAmount: 4,
                    }
                });

                slider.params.control = galleryThumbs;
                galleryThumbs.params.control = slider;

                thumbSliders.push(galleryThumbs);

            }

        });


    };

    /**
     * Init Scroll To
     */
    var scroll_to_position = function () {

        $('a.scroll-to, .scroll-to a').on('click', function () {

            var id = $(this).attr('href');

            $('body, html').animate({
                scrollTop: $(id).offset().top
            }, 1000, 'easeInOutExpo');

            return false
        });

    };

    var parallaxScroll = function () {

        if ($.fn.Parallax && !window.isMobile && !window.isFirefox) {

            /*
             $('.location').each(function () {
             $(this).find('.u-ratio__content').Parallax({prop: 'top', speed: 0.10, start: 20, delay: 0})
             });
             */

            $('.cta .square').Parallax({prop: 'top', speed: -0.10, start: 20, delay: 0})
            $('.team .square-1').Parallax({prop: 'top', speed: -0.10, start: 20, delay: 0})
            $('.team .square-2').Parallax({prop: 'top', speed: 0.10, start: 20, delay: 0})

        }

    };

    /**
     * Hashchange
     *
     var init_hashchange = function (e) {

        $(window).hashchange( function(e){
            var hash = location.hash;

            console.log(hash);

            // If HASH is not empty
            if( hash != '' ) {

                var hash = hash.split('!'),
                    //referent = hash[1],
                    //referent = referent.split('/'),
                    slug = hash[1],
                    that = $('.type-exhibitor[data-slug="'+slug+'"]').data('id'),
                    thatPermalink = $('.type-exhibitor[data-slug="'+slug+'"]').data('permalink');

                //console.log('slug: '+slug);
                //console.log('that: '+that);

                if( slug != 'undefined' || that != 'undefined' ) {
                    if(slug == 'close') {

                        $('#exhibitorModal .close').click();
                        //$('#exhibitorModal').modal('hide');

                    }else {
                        ajax_calling(that, thatPermalink);
                    }
                }

                // When its empty ...
            }else {

                // Hide Modal
                //$('#exhibitorModal').modal('hide');

            }

            return false;
        });

        // Since the event is only triggered when the hash changes, we need to trigger
        // the event now, to handle the hash the page may have loaded with.
        $(window).hashchange();

        $('#exhibitorModal').on('hidden.bs.modal', function (e) {

            location.hash = '!close';

        });


    };


     var enable_modal_view = function() {
        $('.js-exhibitor a').on('click', function(){
            var postId = $(this).closest('.type-exhibitor').data('id');

            //console.log(postId);
            ajax_calling(postId);

            return false;
        });
    };

     // Aussteller AJAX
     var ajax_calling = function (postId, permalink, dontGet) {

        var modal_view = $('#exhibitorModal .modal-body');

        if (!dontGet)
            dontGet = false;

        if (dontGet == false) {

            $.ajax({
                url: MyThAjax.ajaxurl,
                type: 'POST',
                data: {
                    'action': 'getExhibitor',
                    'id': postId,
                    'ajax_call': 'modal_view'
                },
                beforeSend: function () {

                    modal_view.html('<p class="text-center text-loading">Lade Teilnehmer Info...</p>');
                    if($('body').hasClass('modal-open')){

                    }else {
                        $('#exhibitorModal').modal('show');
                    }

                }
            }).done(function (data) {

                ga('send', 'pageview', permalink);

                var imgs = [];
                var imgLoad = imagesLoaded('#exhibitorModal .load-deferred', {background: true});

                imgLoad.on('always', function () {
                    // detect which image is broken
                    for (var i = 0, len = imgLoad.images.length; i < len; i++) {
                        var image = imgLoad.images[i];
                        var result = image.isLoaded ? 'loaded' : 'broken';
                    }
                });

                imgLoad.on('progress', function (instance, image) {
                    var result = image.isLoaded ? 'loaded' : 'broken';
                    imgs.push(image.img.src);
                });

                imgLoad.on('done', function (instance) {
                    console.log('DONE  - all images have been successfully loaded');

                    modal_view.html('').append(data);

                    var slider = new Swiper('.modal .slider-modal', {
                        speed: 350,
                        spaceBetween: 0,
                        effect: 'slide',
                        autoplay: false,
                        grabCursor: false,
                        keyboardControl: false,
                        loop: true,
                        navigation: {
                            prevEl: '.modal .button-prev',
                            nextEl: '.modal .button-next',
                        },
                        preloadImages: false,
                        lazy: true,
                        //lazyLoadingInPrevNext: true,
                        //lazyLoadingInPrevNextAmount: 1
                    });

                });

            });

        }

    };

     */

    /**
     * Init Map
     */
    var init_maps = function () {

        var map = null;

        $('.map').each(function () {

            // create map
            map = new_map($(this));

        });

    };


    var init_photoswiper = function () {

        var initPhotoSwipeFromDOM = function(gallerySelector) {

            // parse slide data (url, title, size ...) from DOM elements
            // (children of gallerySelector)
            var parseThumbnailElements = function(el) {
                var thumbElements = el.childNodes,
                    numNodes = thumbElements.length,
                    items = [],
                    figureEl,
                    linkEl,
                    size,
                    item;

                for(var i = 0; i < numNodes; i++) {

                    figureEl = thumbElements[i]; // <figure> element

                    // include only element nodes
                    if(figureEl.nodeType !== 1) {
                        continue;
                    }

                    linkEl = figureEl.children[0]; // <a> element

                    size = linkEl.getAttribute('data-size').split('x');

                    // create slide object
                    item = {
                        src: linkEl.getAttribute('href'),
                        w: parseInt(size[0], 10),
                        h: parseInt(size[1], 10)
                    };



                    if(figureEl.children.length > 1) {
                        // <figcaption> content
                        item.title = figureEl.children[1].innerHTML;
                    }

                    if(linkEl.children.length > 0) {
                        // <img> thumbnail element, retrieving thumbnail url
                        item.msrc = linkEl.children[0].getAttribute('src');
                    }

                    item.el = figureEl; // save link to element for getThumbBoundsFn
                    items.push(item);
                }

                return items;
            };

            // find nearest parent element
            var closest = function closest(el, fn) {
                return el && ( fn(el) ? el : closest(el.parentNode, fn) );
            };

            // triggers when user clicks on thumbnail
            var onThumbnailsClick = function(e) {
                e = e || window.event;
                e.preventDefault ? e.preventDefault() : e.returnValue = false;

                var eTarget = e.target || e.srcElement;

                // find root element of slide
                var clickedListItem = closest(eTarget, function(el) {
                    return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
                });

                if(!clickedListItem) {
                    return;
                }

                // find index of clicked item by looping through all child nodes
                // alternatively, you may define index via data- attribute
                var clickedGallery = clickedListItem.parentNode,
                    childNodes = clickedListItem.parentNode.childNodes,
                    numChildNodes = childNodes.length,
                    nodeIndex = 0,
                    index;

                for (var i = 0; i < numChildNodes; i++) {
                    if(childNodes[i].nodeType !== 1) {
                        continue;
                    }

                    if(childNodes[i] === clickedListItem) {
                        index = nodeIndex;
                        break;
                    }
                    nodeIndex++;
                }



                if(index >= 0) {
                    // open PhotoSwipe if valid index found
                    openPhotoSwipe( index, clickedGallery );
                }
                return false;
            };

            // parse picture index and gallery index from URL (#&pid=1&gid=2)
            var photoswipeParseHash = function() {
                var hash = window.location.hash.substring(1),
                    params = {};

                if(hash.length < 5) {
                    return params;
                }

                var vars = hash.split('&');
                for (var i = 0; i < vars.length; i++) {
                    if(!vars[i]) {
                        continue;
                    }
                    var pair = vars[i].split('=');
                    if(pair.length < 2) {
                        continue;
                    }
                    params[pair[0]] = pair[1];
                }

                if(params.gid) {
                    params.gid = parseInt(params.gid, 10);
                }

                return params;
            };

            var openPhotoSwipe = function(index, galleryElement, disableAnimation, fromURL) {
                var pswpElement = document.querySelectorAll('.pswp')[0],
                    gallery,
                    options,
                    items;

                items = parseThumbnailElements(galleryElement);

                // define options (if needed)
                options = {

                    // define gallery index (for URL)
                    galleryUID: galleryElement.getAttribute('data-pswp-uid'),

                    getThumbBoundsFn: function(index) {
                        // See Options -> getThumbBoundsFn section of documentation for more info
                        var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
                            pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                            rect = thumbnail.getBoundingClientRect();

                        return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
                    },

                    showHideOpacity: true,

                };

                // PhotoSwipe opened from URL
                if(fromURL) {
                    if(options.galleryPIDs) {
                        // parse real index when custom PIDs are used
                        // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                        for(var j = 0; j < items.length; j++) {
                            if(items[j].pid == index) {
                                options.index = j;
                                break;
                            }
                        }
                    } else {
                        // in URL indexes start from 1
                        options.index = parseInt(index, 10) - 1;
                    }
                } else {
                    options.index = parseInt(index, 10);
                }

                // exit if index not found
                if( isNaN(options.index) ) {
                    return;
                }

                if(disableAnimation) {
                    options.showAnimationDuration = 0;
                }

                // Pass data to PhotoSwipe and initialize it
                gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
                gallery.init();
            };

            // loop through all gallery elements and bind events
            var galleryElements = document.querySelectorAll( gallerySelector );

            for(var i = 0, l = galleryElements.length; i < l; i++) {
                galleryElements[i].setAttribute('data-pswp-uid', i+1);
                galleryElements[i].onclick = onThumbnailsClick;
            }

            // Parse URL and open gallery if it contains #&pid=3&gid=1
            var hashData = photoswipeParseHash();
            if(hashData.pid && hashData.gid) {
                openPhotoSwipe( hashData.pid ,  galleryElements[ hashData.gid - 1 ], true, true );
            }
        };

        // execute above function
        initPhotoSwipeFromDOM('.my-gallery');

    };

    /*
     *  new_map
     *
     *  This function will render a Google Map onto the selected jQuery element
     *
     *  @type	function
     *  @date	8/11/2013
     *  @since	4.3.0
     *
     *  @param	$el (jQuery element)
     *  @return	n/a
     */

    var new_map = function ($el) {

        // var
        var $markers = $el.find('.marker');


        // vars
        var args = {
            zoom: 16,
            center: new google.maps.LatLng(0, 0),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            scrollwheel: false
        };


        // create map
        var map = new google.maps.Map($el[0], args);


        // add a markers reference
        map.markers = [];


        // add markers
        $markers.each(function () {

            add_marker($(this), map);

        });


        // center map
        center_map(map);


        // return
        return map;

    };

    /*
     *  add_marker
     *
     *  This function will add a marker to the selected Google Map
     *
     *  @type	function
     *  @date	8/11/2013
     *  @since	4.3.0
     *
     *  @param	$marker (jQuery element)
     *  @param	map (Google Map object)
     *  @return	n/a
     */

    var add_marker = function ($marker, map) {

        // var
        var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

        // create marker
        var marker = new google.maps.Marker({
            position: latlng,
            map: map
        });

        // add to array
        map.markers.push(marker);

        // if marker contains HTML, add it to an infoWindow
        if ($marker.html()) {
            // create info window
            var infowindow = new google.maps.InfoWindow({
                content: $marker.html()
            });

            // show info window when marker is clicked
            google.maps.event.addListener(marker, 'click', function () {

                infowindow.open(map, marker);

            });
        }

    };

    /*
     *  center_map
     *
     *  This function will center the map, showing all markers attached to this map
     *
     *  @type	function
     *  @date	8/11/2013
     *  @since	4.3.0
     *
     *  @param	map (Google Map object)
     *  @return	n/a
     */

    var center_map = function (map) {

        // vars
        var bounds = new google.maps.LatLngBounds();

        // loop through all markers and create bounds
        $.each(map.markers, function (i, marker) {

            var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());

            bounds.extend(latlng);

        });

        // only 1 marker?
        if (map.markers.length == 1) {
            // set center of map
            map.setCenter(bounds.getCenter());
            map.setZoom(16);
        }
        else {
            // fit to bounds
            map.fitBounds(bounds);
        }

    };


    /**
     * Public
     */
    return {
        init: init
    };

};


// Create mainScript object
window.k = window.k || new mainScript();
console.log('Main Script');      //, k);
dh.ready(k.init);